




























































import { bus } from '@/main';
import { Component, Vue } from 'vue-property-decorator';
import ImageCover from '@/components/ImageCover.vue';

@Component({
    components:{
        ImageCover
    }
})
export default class Category extends Vue {
    data: any | null = null;
    posts: any[] | null = null;
    subcategories: any[] | null = null;

    goTo404Page(){ 
        this.$router.push('/not-found');
    }

    async mounted(){ 
        const slug = this.$route.params.slug;
        
        try{ 
            this.data = await this.$store.dispatch('getItem', `categories/${slug}`);

            bus.$emit('setHeader',{
                title: this.data.content.title, 
                subtitle: this.data.content.subtitle,
                buttons: null,
                bgVideo: null
            });

            //get subcategories
            this.subcategories = await this.$store.dispatch('getSubCategories',this.data.uuid);

            this.posts = [];
            if (!this.subcategories || !this.subcategories.length){
                //get posts
                this.posts = await this.$store.dispatch('getSubItems', this.data.uuid);
                this.subcategories = [
                    this.data
                ];
            }

            for (let i = 0; i < this.subcategories.length; i ++){   
                this.subcategories[i].posts = await this.$store.dispatch('getSubItems', this.subcategories[i].uuid);
            }

            this.$forceUpdate();
            
        }catch(err){ 
            console.error(err);
       //     this.goTo404Page();
        }

        if (!this.data){
            this.goTo404Page();
            return;
        }
    }
    
}
